import React from "react";
import { Link, navigate } from "gatsby";
import styled from "@emotion/styled";
import ErrorBanner from "../../components/errorBanner";
import generateHeaders from "./generateHeaders";

const Submit = styled.button`
  margin: 1rem auto;
  color: #ffffff;
  background-image: linear-gradient(
    to right,
    rgb(255, 61, 48),
    rgb(255, 43, 97)
  );
  padding: 10px 25px;
  border-radius: 5px;
  border-style: none;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;

  &:hover {
    background-image: linear-gradient(
      to left,
      rgb(253, 95, 85),
      rgb(255, 85, 128)
    );
  }

  &:focus {
    outline: 0;
  }

  &:disabled {
    width: 150px;
    text-align: center;
    background-image: linear-gradient(
      to left,
      rgb(253, 95, 85),
      rgb(255, 85, 128)
    );
  }
`;

export default class AgreeToTOS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
      isNotCheckedError: false,
      fetchError: false,
      isLoading: false,
      loadingInterval: null,
      loadingText: "Loading"
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.loadingAnimation = this.loadingAnimation.bind(this);
  }

  toggleCheckbox() {
    this.setState(({ isChecked }) => {
      return {
        isChecked: !isChecked
      };
    });
  }

  componentWillUnmount() {
    if (this.state.isLoading) {
      this.toggleLoading();
    }
  }

  loadingAnimation() {
    if (this.state.loadingText === "Loading...") {
      this.setState({
        loadingText: "Loading"
      });
    } else {
      this.setState(prevState => {
        return {
          loadingText: prevState.loadingText + "."
        };
      });
    }
  }

  toggleLoading(callback = null) {
    if (this.state.loadingInterval != null) {
      window.clearInterval(this.state.loadingInterval);
      this.setState(
        {
          isLoading: false,
          loadingInterval: null
        },
        callback
      );
    } else {
      let loadingInterval = window.setInterval(this.loadingAnimation, 500);
      this.setState(
        {
          isLoading: true,
          loadingInterval: loadingInterval
        },
        callback
      );
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.isChecked !== true) {
      this.setState({ isNotCheckedError: true });
    } else {
      this.toggleLoading(() => {
        generateHeaders()
          .then(headers => {
            return fetch(
              `${process.env.GATSBY_LAMBDA_ENDPOINT_PROD}tos_lambda`,
              {
                method: "GET",
                headers
              }
            );
          })
          .then(response => {
            if (response.status === 204) {
              return window.netlifyIdentity.currentUser().update({});
            } else {
              return Promise.reject(
                "received response code: " + response.status
              );
            }
          })
          .then(() => {
            navigate("/account/");
          })
          .catch(() => {
            this.toggleLoading();
            this.setState({
              fetchError: true
            });
          });
      });
    }
  }

  render() {
    return (
      <div>
        {this.state.fetchError && <ErrorBanner error="TOS agreement fetch" />}
        <form onSubmit={this.handleSubmit}>
          {this.state.isNotCheckedError === true && (
            <p>** You must agree below in order to use our services. **</p>
          )}
          <label>
            <input
              id="terms"
              type="checkbox"
              checked={this.state.isChecked}
              onChange={this.toggleCheckbox}
            />
            I have read and agree to the{" "}
            <Link to="/terms-of-service/">Terms of Service</Link> and{" "}
            <Link to="/privacy-policy/">Privacy Policy</Link>.
          </label>
          <br />
          <Submit type="submit" disabled={this.state.isLoading}>
            {this.state.isLoading ? this.state.loadingText : "Submit"}
          </Submit>
        </form>
      </div>
    );
  }
}
