import generateHeaders from "./generateHeaders";

export default function(source = "withings") {
  return generateHeaders({ "Content-Type": "application/json" })
    .then(headers => {
      return fetch(
        `${process.env.GATSBY_LAMBDA_ENDPOINT_PROD}updateSource_lambda`,
        {
          method: "POST",
          headers,
          body: JSON.stringify({
            source,
            stripe_id: window.netlifyIdentity.currentUser().app_metadata
              .stripe_id
          })
        }
      );
    })
    .then(result => result.json())
    .then(result => {
      if (result.status !== "succeeded") {
        return Promise.reject(result);
      }
    })
    .catch(err => {
      console.error("Error:", err);
    });
}
