//import Account from "../account/account";

import React from "react";
import { Router } from "@reach/router";
import Layout from "../components/layout";
import PrivateRoute from "../account/components/privateRoute";
import Settings from "../account/settings";

const Account = () => (
  <Layout>
    <Router basepath="/account">
      <PrivateRoute path="/*" component={Settings} />
    </Router>
  </Layout>
);

export default Account;
