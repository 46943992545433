import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

const SuccessParagraph = styled.p`
  display: inline;
  color: #4f8a10;
  background-color: #dff2bf;
  padding: 2px 5px;
`;

export function successTimeout(message) {
  this.setState(
    {
      successMessage: message
    },
    () => {
      setTimeout(() => {
        if (this.mounted === false) return;
        this.setState({
          successMessage: null
        });
      }, 4000);
    }
  );
}

export default class SuccessBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: props.message
    };
  }

  render() {
    return <SuccessParagraph>{this.state.message}</SuccessParagraph>;
  }
}

SuccessBanner.propTypes = {
  message: PropTypes.string.isRequired
};

SuccessBanner.defaultProps = {
  message: "Success!"
};
