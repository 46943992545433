import React from "react";
import polarLogo from "../../images/polarflow.svg";
import styled from "@emotion/styled";
import generateHeaders from "./generateHeaders";
import ErrorBanner from "../../components/errorBanner";
import SuccessBanner, { successTimeout } from "../../components/successBanner";
import { ButtonParagraph, StatusP } from "./platformStyledComponents";

const Logo = styled.img`
  width: 160px;
  display: block;
  margin-top: 18px;
  margin-bottom: 3px;
  display: block;
`;
const StyledIframe = styled.iframe`
  width: 500px;
  height: 90px;
  border: none;
  display: block;
  margin-top: 7px;
  padding: 0;
`;

export default class Polar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      polarIsConnected: false,
      polarAccount: null,
      isLoading: false,
      loadingInterval: null,
      loadingText: "Loading",
      fetchError: false,
      fetchErrorDetails: null,
      successMessage: null
    };
    this.handleMessage = this.handleMessage.bind(this);
    this.componentCleanup = this.componentCleanup.bind(this);
    this.successTimeout = successTimeout.bind(this);
    this.handlePolarDisconnect = this.handlePolarDisconnect.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.loadingAnimation = this.loadingAnimation.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
  }

  componentCleanup() {
    window.removeEventListener("message", this.handleMessage);
    window.removeEventListener("beforeunload", this.componentCleanup);
  }

  componentDidMount() {
    if (
      window.netlifyIdentity.currentUser().app_metadata.polar_obfuscated != null
    ) {
      this.setState({
        polarIsConnected: true,
        polarAccount: window.netlifyIdentity.currentUser().app_metadata
          .polar_obfuscated
      });
    }

    window.addEventListener("message", this.handleMessage);
    window.addEventListener("beforeunload", this.componentCleanup);
  }

  sendMessage() {
    let stripe_id = window.netlifyIdentity.currentUser().app_metadata.stripe_id;

    if (document.getElementById("securePolar") != null) {
      console.log("passing message into iframe");
      document
        .getElementById("securePolar")
        .contentWindow.postMessage({ type: "smartscalesync", stripe_id }, "*");
    } else {
      console.log("polar element was null.");

      let body = JSON.stringify({
        url: window.document.URL,
        netlifyUser: JSON.stringify([
          window.netlifyIdentity.currentUser().email,
          window.netlifyIdentity.currentUser().app_metadata
        ]),
        error: "securePolar element was null"
      });

      let headers = { "Content-Type": "application/json" };

      fetch(`${process.env.GATSBY_LAMBDA_ENDPOINT}errorReporting_lambda`, {
        method: "POST",
        headers,
        body
      })
        .then(response => {
          if (response.statusCode === 404) return Promise.reject("404");
          console.log("Reported error successfully.");
        })
        .catch(err => {
          console.log(err);
          console.log(
            "Failed to report this error, please email support@smartscalesync.com"
          );
        });
    }
  }

  componentWillUnmount() {
    this.componentCleanup();
  }

  loadingAnimation() {
    if (this.state.loadingText === "Loading...") {
      this.setState({
        loadingText: "Loading"
      });
    } else {
      this.setState(prevState => {
        return {
          loadingText: prevState.loadingText + "."
        };
      });
    }
  }

  toggleLoading(callback = null) {
    if (this.state.loadingInterval != null) {
      window.clearInterval(this.state.loadingInterval);
      this.setState(
        {
          isLoading: false,
          loadingInterval: null
        },
        callback
      );
    } else {
      let loadingInterval = window.setInterval(this.loadingAnimation, 500);
      this.setState(
        {
          isLoading: true,
          loadingInterval: loadingInterval
        },
        callback
      );
    }
  }

  handlePolarDisconnect(e) {
    e.preventDefault();
    if (
      !window.confirm(
        "Clicking 'OK' will disconnect your Polar Flow account from SmartScaleSync."
      )
    )
      return;
    this.toggleLoading(() => {
      generateHeaders({ "Content-Type": "application/json" })
        .then(headers => {
          return fetch(
            `${process.env.GATSBY_LAMBDA_ENDPOINT_PROD}polarCancel_lambda`,
            {
              method: "POST",
              headers,
              body: JSON.stringify({
                stripe_id: window.netlifyIdentity.currentUser().app_metadata
                  .stripe_id
              })
            }
          );
        })
        .then(result => result.json())
        .then(result => {
          if (result.status === "succeeded") {
            window.netlifyIdentity.currentUser().update({});
            return this.setState({
              polarIsConnected: false,
              polarAccount: null
            });
          } else {
            return Promise.reject(result);
          }
        })
        .then(() => {
          this.toggleLoading();
          this.successTimeout("Successfully disconnected!");
          let stripe_id = window.netlifyIdentity.currentUser().app_metadata
            .stripe_id;
          setTimeout(() => {
            if (document.getElementById("securePolar") != null) {
              console.log("passing message into iframe");
              document
                .getElementById("securePolar")
                .contentWindow.postMessage(
                  { type: "smartscalesync", stripe_id },
                  "*"
                );
            }
          }, 1000);
        })
        .catch(err => {
          this.toggleLoading();
          this.setState({
            fetchError: true,
            fetchErrorDetails: err.message || err.error || err.status || err
          });
        });
    });
  }

  handleMessage({ data }) {
    if (data.type === "securePolar") {
      if (data.result && data.result.status === "succeeded") {
        generateHeaders({ "Content-Type": "application/json" })
          .then(headers => {
            return fetch(
              `${process.env.GATSBY_LAMBDA_ENDPOINT_PROD}polarUpdate_lambda`,
              {
                method: "POST",
                headers,
                body: JSON.stringify({
                  stripe_id: window.netlifyIdentity.currentUser().app_metadata
                    .stripe_id
                })
              }
            );
          })
          .then(result => result.json())
          .then(result => {
            if (result.status === "succeeded") {
              return window.netlifyIdentity.currentUser().update({});
            } else {
              return Promise.reject(result);
            }
          })
          .then(() => {
            this.setState(
              {
                polarIsConnected: true,
                polarAccount: window.netlifyIdentity.currentUser().app_metadata
                  .polar_obfuscated
              },
              () => {
                this.successTimeout("Successfully connected!");
              }
            );
          })
          .catch(err => {
            this.setState({
              fetchError: true,
              fetchErrorDetails: err.message || err.error || err.status || err
            });
          });
      } else if (data.err) {
        console.log(data.err);
        this.setState({
          fetchError: true,
          fetchErrorDetails:
            data.err.message || data.err.error || data.err.status || data.err
        });
      }
    }
  }

  render() {
    return (
      <div>
        <Logo src={polarLogo} />
        {this.state.fetchError && (
          <ErrorBanner error={this.state.fetchErrorDetails} />
        )}
        {!this.state.polarIsConnected ? (
          <div>
            <div>
              <StatusP>Status:</StatusP>
              <StatusP className="disconnected">Not Connected</StatusP>
              {this.state.successMessage && (
                <SuccessBanner message={this.state.successMessage} />
              )}
            </div>
            <StyledIframe
              id="securePolar"
              src="https://s3.amazonaws.com/smartscalesync/polar.html"
              onLoad={() => {
                setTimeout(this.sendMessage, 500);
                setTimeout(this.sendMessage, 1500);
              }}
            />
          </div>
        ) : (
          <div>
            <div>
              <StatusP>
                Status: Connected (
                {this.state.polarAccount ||
                  window.netlifyIdentity.currentUser().app_metadata
                    .polar_obfuscated}
                )
              </StatusP>
              {this.state.successMessage && (
                <SuccessBanner message={this.state.successMessage} />
              )}
            </div>
            <ButtonParagraph
              className={this.state.isLoading && "loading"}
              onClick={
                !this.state.isLoading ? this.handlePolarDisconnect : null
              }
            >
              {this.state.isLoading ? this.state.loadingText : "Disconnect"}
            </ButtonParagraph>
          </div>
        )}
      </div>
    );
  }
}
