export default function generateHeaders(headers = {}) {
  if (window.netlifyIdentity.currentUser()) {
    return window.netlifyIdentity
      .currentUser()
      .jwt()
      .then(token => {
        return Promise.resolve({
          ...headers,
          Authorization: `Bearer ${token}`
        });
      });
  }
  return Promise.resolve(headers);
}
