import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

const ErrorDiv = styled.div`
  border: 2px solid rgb(255, 43, 97);
  margin: 1rem auto;
  max-width: 500px;
  text-align: center;
`;

export default class ErrorBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reported: false,
      error: props.error,
      hidden: props.hidden
    };
  }

  componentDidMount() {
    console.log("Attempting to report error...");

    let body = JSON.stringify({
      url: window.document.URL,
      netlifyUser: JSON.stringify([
        window.netlifyIdentity.currentUser().email,
        window.netlifyIdentity.currentUser().app_metadata
      ]),
      error: this.state.error
    });

    let headers = { "Content-Type": "application/json" };

    fetch(`${process.env.GATSBY_LAMBDA_ENDPOINT}errorReporting_lambda`, {
      method: "POST",
      headers,
      body
    })
      .then(response => {
        //console.log(response);
        if (response.statusCode === 404) return Promise.reject("404");
        console.log("Reported error successfully.");
        this.setState({
          reported: true
        });
      })
      .catch(err => {
        console.log(err);
        console.log(
          "Failed to report this error, please email support@smartscalesync.com"
        );
      });
  }

  render() {
    return (
      this.state.hidden !== true && (
        <ErrorDiv>
          <h3>There&apos;s something wrong...</h3>
          {this.state.reported ? (
            <p>
              This error has been reported. Thank you for your patience, if this
              problem persists please contact support@smartscalesync.com.
            </p>
          ) : (
            <p>
              Please contact support@smartscalesync.com. Thank your for your
              patience, we really appreciate your help diagnosing this issue.
            </p>
          )}
        </ErrorDiv>
      )
    );
  }
}

ErrorBanner.propTypes = {
  error: PropTypes.string.isRequired,
  hidden: PropTypes.bool
};

ErrorBanner.defaultProps = {
  error: "Not Available",
  hidden: false
};
