import React from "react";
import garminLogo from "../../images/garmin-logo.png";
import styled from "@emotion/styled";
import generateHeaders from "./generateHeaders";
import uuid from "uuid/v4";
import queryString from "query-string";
import { navigate } from "gatsby";
import ErrorBanner from "../../components/errorBanner";
import SuccessBanner, { successTimeout } from "../../components/successBanner";
import { ButtonParagraph, StatusP } from "./platformStyledComponents";

const Logo = styled.img`
  width: 115px;
  margin-top: 6px;
  margin-bottom: 0;
  display: block;
`;
const StyledIframe = styled.iframe`
  width: 500px;
  height: 90px;
  border: none;
  display: block;
  margin-top: 7px;
  padding: 0;
`;

export default class Garmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      garminIsConnected: false,
      garminAccount: null,
      isLoading: false,
      loadingInterval: null,
      loadingText: "Loading",
      fetchError: false,
      fetchErrorDetails: null,
      successMessage: null,
      asDestination: false
    };
    this.handleMessage = this.handleMessage.bind(this);
    this.componentCleanup = this.componentCleanup.bind(this);
    this.successTimeout = successTimeout.bind(this);
    this.handleGarminDisconnect = this.handleGarminDisconnect.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.loadingAnimation = this.loadingAnimation.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.handleGarminSourceClick = this.handleGarminSourceClick.bind(this);
    this.handleGarminAsDestinationClick = this.handleGarminAsDestinationClick.bind(
      this
    );
  }

  componentCleanup() {
    window.removeEventListener("message", this.handleMessage);
    window.removeEventListener("beforeunload", this.componentCleanup);
  }

  componentDidMount() {
    this.mounted = true;
    if (
      window.netlifyIdentity.currentUser().app_metadata.garminIntegration ===
      true
    ) {
      this.setState({
        garminIsConnected: true,
        garminAccount:
          window.netlifyIdentity.currentUser().app_metadata.source === "garmin"
            ? null
            : window.netlifyIdentity.currentUser().app_metadata
                .garmin_obfuscated
      });
    }

    let garminNonce =
      typeof window !== "undefined" &&
      window.localStorage.getItem("garminNonce")
        ? JSON.parse(window.localStorage.getItem("garminNonce"))
        : null;

    window.addEventListener("message", this.handleMessage);
    window.addEventListener("beforeunload", this.componentCleanup);

    if (this.props.query != null && this.props.query !== "") {
      const values = queryString.parse(this.props.query);
      if (garminNonce === values.state) {
        //console.log("Matching nonce!!!");
        if (
          !values.oauth_token ||
          !values.oauth_verifier ||
          !values.oauth_token_secret ||
          values.oauth_verifier === "null"
        ) {
          if (this.state.isLoading) this.toggleLoading();
          window.localStorage.removeItem("garminNonce");
          console.error(
            "Error: something is wrong, missing verifier, oauth token, or oauth token secret..."
          );
          this.setState({
            fetchError: true,
            fetchErrorDetails:
              "missing verifier, oauth token, or oauth token secret..."
          });
        } else {
          this.toggleLoading(() => {
            generateHeaders({ "Content-Type": "application/json" })
              .then(headers => {
                if (values.error != null) {
                  return Promise.reject(values.error);
                }
                return fetch(
                  `${process.env.GATSBY_LAMBDA_ENDPOINT_PROD}getGarminTokens_lambda`,
                  {
                    method: "POST",
                    headers,
                    body: JSON.stringify({
                      nonce: uuid(),
                      oauth_verifier: values.oauth_verifier,
                      oauth_token: values.oauth_token,
                      oauth_token_secret: values.oauth_token_secret,
                      stripe_id: window.netlifyIdentity.currentUser()
                        .app_metadata.stripe_id,
                      source: this.props.source
                    })
                  }
                );
              })
              .then(result => result.json())
              .then(result => {
                if (result.status === "succeeded") {
                  window.localStorage.removeItem("garminNonce");
                  return window.netlifyIdentity.currentUser().update({});
                } else {
                  return Promise.reject(result);
                }
              })
              .then(() => {
                this.setState(
                  {
                    garminIsConnected: true
                  },
                  () => {
                    this.toggleLoading();
                    this.successTimeout("Successfully connected!");
                    if (this.props.source !== "garmin") {
                      this.props.updateSource("garmin", true);
                    }
                  }
                );
              })
              .catch(error => {
                this.toggleLoading();
                window.localStorage.removeItem("garminNonce");
                console.error("Error:", error);
                this.setState({
                  fetchError: true,
                  fetchErrorDetails:
                    error.message || error.error || error.status || error
                });
              });
          });
        }
      } else {
        navigate("/account/", { replace: true });
      }
    }
  }

  sendMessage() {
    let stripe_id = window.netlifyIdentity.currentUser().app_metadata.stripe_id;

    if (document.getElementById("secureGarmin") != null) {
      console.log("passing message into iframe");
      document
        .getElementById("secureGarmin")
        .contentWindow.postMessage({ type: "smartscalesync", stripe_id }, "*");
    }
  }

  componentWillUnmount() {
    this.componentCleanup();
    if (this.state.isLoading) {
      this.toggleLoading();
    }
    this.mounted = false;
  }

  loadingAnimation() {
    if (this.state.loadingText === "Loading...") {
      this.setState({
        loadingText: "Loading"
      });
    } else {
      this.setState(prevState => {
        return {
          loadingText: prevState.loadingText + "."
        };
      });
    }
  }

  toggleLoading(callback = null) {
    if (this.state.loadingInterval != null) {
      window.clearInterval(this.state.loadingInterval);
      this.setState(
        {
          isLoading: false,
          loadingInterval: null
        },
        callback
      );
    } else {
      let loadingInterval = window.setInterval(this.loadingAnimation, 500);
      this.setState(
        {
          isLoading: true,
          loadingInterval: loadingInterval
        },
        callback
      );
    }
  }

  handleGarminSourceClick(e) {
    e.preventDefault();
    //console.log("handleGarminSourceClick()");
    const state = uuid() + "-garmin";
    window.localStorage.setItem("garminNonce", JSON.stringify(state));

    this.toggleLoading(() => {
      fetch(
        `${process.env.GATSBY_LAMBDA_ENDPOINT}getGarminUnauthorizedTokens_lambda`,
        {
          method: "POST",
          body: JSON.stringify({
            nonce: state
          })
        }
      )
        .then(result => result.json())
        .then(result => {
          //console.log(result);
          const garminAuthUrl = `https://connect.garmin.com/oauthConfirm?${result.oauth_token}&oauth_callback=${process.env.GATSBY_REDIRECT_URI}/?state=${state}%26oauth_token_secret=${result.oauth_token_secret}`;
          //console.log(garminAuthUrl);
          window.location.assign(garminAuthUrl);
        });
    });
  }

  handleGarminDisconnect(e) {
    e.preventDefault();
    if (
      !window.confirm(
        "Clicking 'OK' will disconnect your Garmin account from SmartScaleSync."
      )
    )
      return;
    this.toggleLoading(() => {
      generateHeaders({ "Content-Type": "application/json" })
        .then(headers => {
          return fetch(
            `${process.env.GATSBY_LAMBDA_ENDPOINT_PROD}garminCancel_lambda`,
            {
              method: "POST",
              headers,
              body: JSON.stringify({
                stripe_id: window.netlifyIdentity.currentUser().app_metadata
                  .stripe_id,
                source: this.props.source
              })
            }
          );
        })
        .then(result => result.json())
        .then(result => {
          if (result.status === "succeeded") {
            window.netlifyIdentity.currentUser().update({});
            return this.setState({
              garminIsConnected: false,
              garminAccount: null,
              asDestination: false
            });
          } else {
            return Promise.reject(result);
          }
        })
        .then(() => {
          this.toggleLoading();
          this.successTimeout("Successfully disconnected!");
          if (this.props.source === "garmin") {
            this.props.updateSource("", true);
          }
          let stripe_id = window.netlifyIdentity.currentUser().app_metadata
            .stripe_id;
          setTimeout(() => {
            if (document.getElementById("secureGarmin") != null) {
              console.log("passing message into iframe");
              document
                .getElementById("secureGarmin")
                .contentWindow.postMessage(
                  { type: "smartscalesync", stripe_id },
                  "*"
                );
            }
          }, 1000);
        })
        .catch(err => {
          this.toggleLoading();
          this.setState({
            fetchError: true,
            fetchErrorDetails: err.message || err.error || err.status || err
          });
        });
    });
  }

  handleGarminAsDestinationClick(e) {
    e.preventDefault();
    this.setState({ asDestination: true });
  }

  handleMessage({ data }) {
    if (data.type === "secureGarmin") {
      if (data.result && data.result.status === "succeeded") {
        generateHeaders({ "Content-Type": "application/json" })
          .then(headers => {
            return fetch(
              `${process.env.GATSBY_LAMBDA_ENDPOINT_PROD}garminUpdate_lambda`,
              {
                method: "POST",
                headers,
                body: JSON.stringify({
                  stripe_id: window.netlifyIdentity.currentUser().app_metadata
                    .stripe_id
                })
              }
            );
          })
          .then(result => result.json())
          .then(result => {
            if (result.status === "succeeded") {
              return window.netlifyIdentity.currentUser().update({});
            } else {
              return Promise.reject(result);
            }
          })
          .then(() => {
            this.setState(
              {
                garminIsConnected: true,
                garminAccount: window.netlifyIdentity.currentUser().app_metadata
                  .garmin_obfuscated
              },
              () => {
                this.successTimeout("Successfully connected!");
              }
            );
          })
          .catch(err => {
            this.setState({
              fetchError: true,
              fetchErrorDetails: err.message || err.error || err.status || err
            });
          });
      } else if (data.err) {
        console.log(data.err);
        this.setState({
          fetchError: true,
          fetchErrorDetails:
            data.err.message || data.err.error || data.err.status || data.err
        });
      }
    }
  }

  render() {
    return (
      <div>
        {/* <Logo src={garminLogo} /> */}
        <h2 style={{ marginBottom: "5px" }}>Garmin</h2>
        {this.state.fetchError && (
          <ErrorBanner error={this.state.fetchErrorDetails} />
        )}
        {!this.state.garminIsConnected ? (
          <div>
            <div>
              <StatusP>Status:</StatusP>
              <StatusP className="disconnected">Not Connected</StatusP>
              {this.state.successMessage && (
                <SuccessBanner message={this.state.successMessage} />
              )}
            </div>
            {this.state.asDestination ? (
              <StyledIframe
                id="secureGarmin"
                src="https://s3.amazonaws.com/smartscalesync/index.html"
                onLoad={() => {
                  setTimeout(this.sendMessage, 500);
                }}
              />
            ) : (
              <>
                <ButtonParagraph
                  className={this.state.isLoading && "loading"}
                  onClick={
                    !this.state.isLoading ? this.handleGarminSourceClick : null
                  }
                >
                  {this.state.isLoading
                    ? this.state.loadingText
                    : "Connect as Source"}
                </ButtonParagraph>
                {/* {!this.state.isLoading && (
                  <ButtonParagraph
                    className={this.state.isLoading && "loading"}
                    onClick={
                      !this.state.isLoading
                        ? this.handleGarminAsDestinationClick
                        : null
                    }
                  >
                    Connect as Destination
                  </ButtonParagraph>
                )} */}
              </>
            )}
          </div>
        ) : (
          <div>
            <div>
              <StatusP>
                Status: Connected{" "}
                {this.props.source === "garmin"
                  ? "as source"
                  : `(${this.state.garminAccount ||
                      window.netlifyIdentity.currentUser().app_metadata
                        .garmin_obfuscated})`}
              </StatusP>
              {this.state.successMessage && (
                <SuccessBanner message={this.state.successMessage} />
              )}
            </div>
            <ButtonParagraph
              className={this.state.isLoading && "loading"}
              onClick={
                !this.state.isLoading ? this.handleGarminDisconnect : null
              }
            >
              {this.state.isLoading ? this.state.loadingText : "Disconnect"}
            </ButtonParagraph>
          </div>
        )}
      </div>
    );
  }
}
