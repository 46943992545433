import React from "react";
import AgreeToTOS from "./components/agreeToTOS";
import Withings from "./components/withings";
import Fitbit from "./components/fitbit";
// import Googlefit from "./components/googlefit";
import Strava from "./components/strava";
import Garmin from "./components/garmin";
import TrainerRoad from "./components/trainerroad";
import Peloton from "./components/peloton";
import Polar from "./components/polar";
import Concept2 from "./components/concept2";
// import MFP from "./components/mfp";
//import BetaFeature from "./components/betaFeature";
import Billing from "./components/billing";
import styled from "@emotion/styled";
import updateSource from "./components/updateSource";
import requestBeta from "./components/requestBeta";

const ContentDiv = styled.div`
  text-align: left;
`;

const ButtonParagraph = styled.p`
  margin: 0.25rem auto;
  color: #007fb6;
  font-size: 0.85rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: rgb(0, 158, 255);
  }

  &.loading {
    color: rgb(153, 153, 153);
    display: inline;
    margin-right: 10px;
    font-size: 1rem;
  }

  &.refresh {
    color: #007fb6;
    display: inline;
    margin-right: 10px;
    font-size: 1rem;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: rgb(0, 158, 255);
    }
  }
`;

export default class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      source: ""
    };
    this.handleSourceUpdate = this.handleSourceUpdate.bind(this);
  }

  componentDidMount() {
    if (window.netlifyIdentity.currentUser().app_metadata.source != null) {
      this.setState({
        source: window.netlifyIdentity.currentUser().app_metadata.source
      });
    }
  }

  handleSourceUpdate(newSource, stateOnly = false, callback = () => {}) {
    //console.log("handleSourceUpdate");
    if (stateOnly) {
      this.setState(
        {
          source: newSource
        },
        callback
      );
    } else {
      updateSource(newSource)
        .then(() => {
          window.netlifyIdentity.currentUser().update({});
        })
        .then(() => {
          // console.log(
          //   "new source:",
          //   window.netlifyIdentity.currentUser().app_metadata.source
          // );
          this.setState(
            {
              source: newSource
            },
            callback
          );
        });
    }
  }

  render() {
    if (
      window.netlifyIdentity.currentUser().app_metadata.tos_agreement !== true
    ) {
      return <AgreeToTOS />;
    }

    return (
      <div>
        <ContentDiv>
          <Billing
            query={this.props.location.search}
            updateSource={this.handleSourceUpdate}
          />
          {window.netlifyIdentity.currentUser().app_metadata.stripe_id !=
            null && (
            <div>
              <Fitbit
                query={
                  this.props.location.search.includes("fitbit")
                    ? this.props.location.search
                    : null
                }
                source={this.state.source}
                updateSource={this.handleSourceUpdate}
              />
              <Garmin
                query={
                  this.props.location.search.includes("garmin")
                    ? this.props.location.search
                    : null
                }
                source={this.state.source}
                updateSource={this.handleSourceUpdate}
              />
              {/* <Googlefit /> */}
              <TrainerRoad />
              <Strava
                query={
                  this.props.location.search.includes("strava")
                    ? this.props.location.search
                    : null
                }
              />
              <Polar />
              <Peloton />
              <Concept2 />
            </div>
          )}
        </ContentDiv>
      </div>
    );
  }
}
