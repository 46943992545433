import React from "react";
import styled from "@emotion/styled";
import generateHeaders from "./generateHeaders";
import ErrorBanner from "../../components/errorBanner";
import SuccessBanner, { successTimeout } from "../../components/successBanner";
import { navigate } from "gatsby";
import queryString from "query-string";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);

const StyledButton = styled.button`
  color: #ffffff;
  background-image: linear-gradient(
    to right,
    rgb(255, 61, 48),
    rgb(255, 43, 97)
  );
  padding: 10px 25px;
  margin: 0 auto;
  margin-top: 1rem;
  border-radius: 5px;
  border-style: none;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;

  &:hover {
    background-image: linear-gradient(
      to left,
      rgb(253, 95, 85),
      rgb(255, 85, 128)
    );
  }

  &:disabled {
    width: 150px;
    text-align: center;
    background-image: linear-gradient(
      to left,
      rgb(253, 95, 85),
      rgb(255, 85, 128)
    );
  }

  &:focus {
    outline: 0;
  }
`;

const StyledA = styled.a`
  text-decoration: none;
  color: #007fb6;
  cursor: pointer;
  display: inline;

  &:hover {
    text-decoration: underline;
    color: rgb(0, 158, 255);
  }
`;

const ButtonParagraph = styled.p`
  margin: 0.25rem auto;

  &.monthly {
    color: #007fb6;
    font-size: 0.85rem;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: rgb(0, 158, 255);
    }
  }

  &.loading {
    color: rgb(153, 153, 153);
    display: inline;
    margin-right: 10px;
    font-size: 1rem;
  }

  &.refresh {
    color: #007fb6;
    display: inline;
    margin-right: 10px;
    font-size: 1rem;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: rgb(0, 158, 255);
    }
  }

  &.billingPortal {
    color: #007fb6;
    display: inline;
    margin-right: 10px;
    font-size: 1rem;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: rgb(0, 158, 255);
    }
  }
`;

const CenteredDiv = styled.div`
  text-align: center;
`;

const StyledP = styled.p`
  margin: 5px auto;
  padding: 0;

  &.attention {
    color: rgb(255, 43, 97);
    font-weight: bold;
  }
`;

const StyledH3 = styled.h3`
  margin: 0;
  margin-right: 5px;
  padding: 0;
  display: inline;
  font-family: "Roboto", serif;
`;
const StyledH4 = styled.h4`
  margin: 0;
  margin-right: 5px;
  padding: 0;
  font-family: "Roboto", serif;
`;

export default class Billing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      planButtons: {
        disabled: false,
        text: "Start Yearly Plan ($18.99/year)",
        loadingText: "Loading",
        loadingInterval: null,
        isLoading: false
      },
      billingPortal: {
        isLoading: false,
        loadingText: "Loading",
        text: "Manage subscription",
        loadingInterval: null
      },
      refreshButton: {
        isLoading: false,
        loadingText: "Loading",
        text: "Refresh",
        loadingInterval: null
      },
      stripe: {
        subscriptionType: null, // Monthly or Yearly
        subscriptionStatus: "inactive",
        subscriptionText: null, // Your next bill... Your subscription was canceled...
        subscriptionWillEnd: null,
        upload_timestamp: null // last garmin upload attempt
      },
      fetchError: false,
      fetchErrorDetails: "",
      successMessage: null
    };
    this.successTimeout = successTimeout.bind(this);
    this.stripeSubscribe = this.stripeSubscribe.bind(this);
    this.stripeBillingPortal = this.stripeBillingPortal.bind(this);
    this.stripeStatus = this.stripeStatus.bind(this);
    this.loadingAnimation = this.loadingAnimation.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
  }

  componentDidMount() {
    //console.log("mounting");
    this.mounted = true;

    if (window.sessionStorage.getItem("stripe")) {
      //console.log("fetching from sessionStorage");
      this.setState({
        stripe: JSON.parse(window.sessionStorage.getItem("stripe"))
      });
    } else if (
      window.netlifyIdentity.currentUser().app_metadata.stripe_id != null
    ) {
      this.stripeStatus().catch(err => {
        //console.error("Here Error:", err)
        this.setState({
          fetchError: true,
          fetchErrorDetails: err.message || err
        });
      });
    }

    let stripe_session_id =
      typeof window !== "undefined" &&
      window.localStorage.getItem("stripe_session_id")
        ? JSON.parse(window.localStorage.getItem("stripe_session_id"))
        : null;

    if (this.props.query != null && this.props.query !== "") {
      const values = queryString.parse(this.props.query);
      //console.log(`query values: ${JSON.stringify(values)}`);
      //console.log(`values.session_id ${values.session_id}`)
      if (stripe_session_id === values.session_id) {
        //console.log("stripeConfirmPayment_lambda");
        this.toggleLoading(
          "planButtons",
          () => {
            generateHeaders({ "Content-Type": "application/json" })
              .then(headers => {
                return fetch(
                  `${process.env.GATSBY_LAMBDA_ENDPOINT_PROD}stripeConfirmPayment_lambda`,
                  {
                    method: "POST",
                    headers,
                    body: JSON.stringify({
                      sessionId: values.session_id
                    })
                  }
                );
              })
              .then(result => result.json())
              .then(() => {
                //console.log("netlify update user");
                return window.netlifyIdentity.currentUser().update({});
              })
              .then(() => {
                window.localStorage.removeItem("stripe_session_id");
              })
              .then(() => {
                return this.stripeStatus();
              })
              .then(() => {
                this.toggleLoading("planButtons");
                this.successTimeout("Successfully subscribed!");
              })
              .then(() => {
                navigate("/account", { replace: true });
              })
              .catch(error => {
                this.toggleLoading("planButtons");
                console.error("Error:", error);
                window.localStorage.removeItem("stripe_session_id");
                this.setState({
                  fetchError: true,
                  fetchErrorDetails:
                    error.message || error.error || error.status || error
                });
              });
          },
          "Processing"
        );
      } else if (
        values.stripeRefresh === true ||
        values.stripeRefresh === "true"
      ) {
        return this.stripeStatus().then(() => {
          navigate("/account", { replace: true });
        });
      } else {
        navigate("/account", { replace: true });
      }
    }
  }

  componentWillUnmount() {
    //console.log("unmounting");
    if (this.state.billingPortal.isLoading) {
      this.toggleLoading("billingPortal");
    }
    if (this.state.planButtons.isLoading) {
      this.toggleLoading("planButtons");
    }
    this.mounted = false;
  }

  updateStripeSessionStorage(stripe = {}) {
    if (typeof window !== "undefined") {
      window.sessionStorage.setItem("stripe", JSON.stringify(stripe));
    }
  }

  loadingAnimation(element = "cancelButton") {
    if (this.state[element].loadingText === "Loading...") {
      this.setState(prevState => {
        return {
          [element]: {
            ...prevState[element],
            loadingText: "Loading"
          }
        };
      });
    } else {
      this.setState(prevState => {
        return {
          [element]: {
            ...prevState[element],
            loadingText: prevState[element].loadingText + "."
          }
        };
      });
    }
  }

  toggleLoading(element = "cancelButton", callback = null) {
    if (this.state[element].loadingInterval != null) {
      window.clearInterval(this.state[element].loadingInterval);
      this.setState(prevState => {
        return {
          [element]: {
            ...prevState[element],
            isLoading: false,
            loadingInterval: null
          }
        };
      }, callback);
    } else {
      let loadingInterval = window.setInterval(
        this.loadingAnimation.bind(null, element),
        500
      );
      this.setState(prevState => {
        return {
          [element]: {
            ...prevState[element],
            isLoading: true,
            loadingInterval: loadingInterval
          }
        };
      }, callback);
    }
  }

  stripeSubscribe = function(plan = "Yearly", e) {
    e.preventDefault();
    console.log(`Trying to subscribe to ${plan}`);

    let sessionId = null;

    this.toggleLoading(
      "planButtons",
      () => {
        generateHeaders({ "Content-Type": "application/json" })
          .then(headers => {
            return fetch(
              `${process.env.GATSBY_LAMBDA_ENDPOINT_PROD}stripeCreateSession_lambda`,
              {
                method: "POST",
                headers: headers,
                body: JSON.stringify({
                  plan
                })
              }
            );
          })
          .then(res => res.json())
          .then(SESSION_ID => {
            //console.log(`sessionId ${SESSION_ID}`)
            sessionId = SESSION_ID;
            window.localStorage.setItem(
              "stripe_session_id",
              JSON.stringify(sessionId)
            );
            return stripePromise;
          })
          .then(stripe => {
            return stripe.redirectToCheckout({
              sessionId
            });
          })
          .then(result => {
            if (result && result.error) {
              console.log(result.error.message);
              Promise.reject(result.error.message);
            }
          })
          .catch(error => {
            console.log(error);
            window.localStorage.removeItem("stripe_session_id");
            this.toggleLoading("planButtons");
            this.setState({
              fetchError: true,
              fetchErrorDetails: error.message || error
            });
          });
      },
      "Redirecting"
    );
  };

  stripeStatus() {
    return generateHeaders({ "Content-Type": "application/json" })
      .then(headers => {
        //console.log(process.env.GATSBY_LAMBDA_ENDPOINT_PROD);
        return fetch(
          `${process.env.GATSBY_LAMBDA_ENDPOINT_PROD}stripeStatus_lambda`,
          {
            //
            method: "POST",
            headers: headers,
            body: JSON.stringify({
              stripe_id: window.netlifyIdentity.currentUser().app_metadata
                .stripe_id,
              subscription_id: window.netlifyIdentity.currentUser().app_metadata
                .subscription_id
            })
          }
        );
      })
      .then(result => result.json())
      .then(result => {
        if (result.status !== "succeeded") {
          return Promise.reject(
            result.error ||
              result.status ||
              "Inside stripeStatus(), no error message recorded"
          );
        }

        let currentPeriodEnd =
          result.currentPeriodEnd != null
            ? new Date(result.currentPeriodEnd).toLocaleDateString()
            : null;

        let subscriptionText = this.state.subscriptionText;

        //console.log(result.subscriptionStatus);
        if (result.subscriptionStatus === "inactive") {
          //console.log("Hi");
          window.netlifyIdentity.currentUser().update({});
          subscriptionText = "";
        } else if (
          result.subscriptionStatus === "active" &&
          result.cancelAtPeriodEnd
        ) {
          subscriptionText = `Your subscription was canceled and you will not be billed again. Your membership will expire at the end of the current billing period on ${currentPeriodEnd}.`;
        } else if (result.subscriptionStatus === "active") {
          subscriptionText = `Your next bill will be ${
            currentPeriodEnd != null
              ? "on " + currentPeriodEnd.toString() + " "
              : ""
          }for $${result.subscriptionAmount}.`;
        } else {
          // past_due
          subscriptionText = `There's something wrong with your current subscription. The status is marked as ${result.subscriptionStatus}. Please contact support@smartscalesync.com with any questions.`;
        }

        let stripe = {
          subscriptionType: result.subscriptionName,
          subscriptionStatus: result.subscriptionStatus,
          subscriptionText: subscriptionText,
          subscriptionWillEnd: result.cancelAtPeriodEnd,
          upload_timestamp: result.upload_timestamp
        };

        //console.log("result.source:", result.source);
        // console.log(
        //   "netlifyIdentity source:",
        //   window.netlifyIdentity.currentUser().app_metadata.source
        // );
        if (
          result.source !== "" &&
          result.source !==
            window.netlifyIdentity.currentUser().app_metadata.source
        ) {
          //console.log("here");
          this.props.updateSource(result.source, false, () => {
            // console.log(
            //   "netlifyIdentity updated source:",
            //   window.netlifyIdentity.currentUser().app_metadata.source
            // );
          });
        }
        // antipattern but using for now
        if (this.mounted) {
          this.setState({ stripe });
          this.updateStripeSessionStorage(stripe);
        }
      })
      .catch(err => {
        //console.log("ERROR in Status", err);
        return Promise.reject(err);
      });
  }

  stripeBillingPortal = function(e) {
    e.preventDefault();

    this.toggleLoading(
      "billingPortal",
      () => {
        generateHeaders({ "Content-Type": "application/json" })
          .then(headers => {
            return fetch(
              `${process.env.GATSBY_LAMBDA_ENDPOINT_PROD}stripeCreateBillingSession_lambda`,
              {
                method: "POST",
                headers: headers,
                body: JSON.stringify({
                  stripe_id: window.netlifyIdentity.currentUser().app_metadata
                    .stripe_id
                })
              }
            );
          })
          .then(res => res.json())
          .then(url => {
            if (!url.includes("https://billing.stripe.com"))
              Promise.reject("Failed to start a billing portal session.");
            window.location.assign(url);
          })
          .catch(error => {
            console.log(error);
            this.toggleLoading("billingPortal");
            this.setState({
              fetchError: true,
              fetchErrorDetails: error.message || error
            });
          });
      },
      "Redirecting"
    );
  };

  render() {
    const StartSubscription = () => (
      <CenteredDiv>
        Your membership has expired or is not active. You are unable to start a
        membership on the legacy site as it is being phased out.
      </CenteredDiv>
    );

    // no stripe id - first time subscription sign up
    if (window.netlifyIdentity.currentUser().app_metadata.stripe_id == null) {
      return (
        <CenteredDiv>
          {this.state.fetchError && (
            <ErrorBanner error={this.state.fetchErrorDetails} />
          )}
          {/*<StyledH3>Subscribe and your withings scale will sync every hour with garmin connect.</StyledH3>*/}
          <StyledH4>
            Full refund within the first 7 days if you&apos;re not 100%
            satisfied.
          </StyledH4>
          {StartSubscription()}
          <br />
          {this.state.successMessage && (
            <SuccessBanner message={this.state.successMessage} />
          )}
        </CenteredDiv>
      );
    } else {
      return (
        <div>
          {this.state.stripe.subscriptionStatus !== "inactive" && (
            <>
              <StyledH3>Subscription</StyledH3>
              {this.state.successMessage && (
                <SuccessBanner message={this.state.successMessage} />
              )}
              {this.state.fetchError && (
                <ErrorBanner error={this.state.fetchErrorDetails} />
              )}
              <StyledP>
                Membership: {this.state.stripe.subscriptionType} (
                {this.state.stripe.subscriptionStatus})
              </StyledP>
              {this.state.stripe.upload_timestamp != null &&
                this.state.stripe.upload_timestamp !== "" && (
                  <StyledP title="This is not the timestamp of your last weighin. It's the timestamp of the most recent attempt to sync a weighin. Sometimes syncs will fail and will be retried several hours after the weighin occured.">
                    Timestamp of last sync attempt:{" "}
                    {this.state.stripe.upload_timestamp != null &&
                    this.state.stripe.upload_timestamp !== ""
                      ? new Date(
                          Number(this.state.stripe.upload_timestamp)
                        ).toString()
                      : "NA"}{" "}
                    {/* <ButtonParagraph
                      className={
                        !this.state.refreshButton.isLoading
                          ? "refresh"
                          : "loading"
                      }
                      onClick={
                        !this.state.refreshButton.isLoading
                          ? () => {
                              this.toggleLoading("refreshButton", () => {
                                this.stripeStatus()
                                  .then(() =>
                                    this.toggleLoading("refreshButton")
                                  )
                                  .catch(() =>
                                    this.toggleLoading("refreshButton")
                                  );
                              });
                            }
                          : null
                      }
                    >
                      {!this.state.refreshButton.isLoading
                        ? this.state.refreshButton.text
                        : this.state.refreshButton.loadingText}
                    </ButtonParagraph> */}
                  </StyledP>
                )}
              {this.state.stripe.subscriptionText !== "" && (
                <StyledP>Billing: {this.state.stripe.subscriptionText}</StyledP>
              )}
            </>
          )}

          {this.state.stripe.subscriptionStatus === "inactive" &&
            StartSubscription()}
        </div>
      );
    }
  }
}
