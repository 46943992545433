import React from "react";
import { isLoggedIn } from "../services/auth";
import { navigate } from "gatsby";

class PrivateRoute extends React.Component {
  componentDidMount = () => {
    const { location } = this.props;
    //console.log("Mounted Private Route - Check for Login");
    if (!isLoggedIn() && location.pathname !== `/`) {
      // If the user is not logged in, redirect to the login page.
      //console.log("navigating to home");
      //console.log(`current url: ${document.URL}`)
      navigate(`/`);
    }
  };

  render() {
    const { component: Component, ...rest } = this.props; // const { component: Component, location, ...rest } = this.props;
    //console.log("Rendering Private Route - Check For Login");
    return isLoggedIn() ? <Component {...rest} /> : null;
  }
}

export default PrivateRoute;
