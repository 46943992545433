import React from "react";
import withingsLogo from "../../images/logo-withings-black-cropped.png";
import styled from "@emotion/styled";
import generateHeaders from "./generateHeaders";
import uuid from "uuid/v4";
import queryString from "query-string";
import { navigate } from "gatsby";
import ErrorBanner from "../../components/errorBanner";
import SuccessBanner, { successTimeout } from "../../components/successBanner";
import { ButtonParagraph, StatusP } from "./platformStyledComponents";

const Logo = styled.img`
  width: 115px;
  display: block;
  margin-top: 16px;
`;

export default class Withings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      loadingInterval: null,
      loadingText: "Loading",
      fetchError: false,
      fetchErrorDetails: null,
      successMessage: null,
      withingsIsConnected: false
    };

    this.handleWithingsClick = this.handleWithingsClick.bind(this);
    this.successTimeout = successTimeout.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.loadingAnimation = this.loadingAnimation.bind(this);
    this.handleWithingsDisconnect = this.handleWithingsDisconnect.bind(this);
  }

  componentWillUnmount() {
    if (this.state.isLoading) {
      this.toggleLoading();
    }
    this.mounted = false;
  }

  componentDidMount() {
    //console.log("withings mounted");
    this.mounted = true;

    if (
      window.netlifyIdentity.currentUser().app_metadata.withingsIntegration !=
      null
    ) {
      this.setState({ withingsIsConnected: true });
    }

    let withingsNonce =
      typeof window !== "undefined" &&
      window.localStorage.getItem("withingsNonce")
        ? JSON.parse(window.localStorage.getItem("withingsNonce"))
        : null;

    if (this.props.query != null && this.props.query !== "") {
      const values = queryString.parse(this.props.query);
      console.log("calling getWithingsToken_lambda");
      if (withingsNonce === values.state) {
        this.toggleLoading(() => {
          generateHeaders({ "Content-Type": "application/json" })
            .then(headers => {
              return fetch(
                `${process.env.GATSBY_LAMBDA_ENDPOINT}getWithingsToken_lambda`,
                {
                  method: "POST",
                  headers,
                  body: JSON.stringify({
                    code: values.code,
                    stripe_id: window.netlifyIdentity.currentUser().app_metadata
                      .stripe_id,
                    source: this.props.source
                  })
                }
              );
            })
            .then(result => result.json())
            .then(result => {
              if (result.status === "succeeded") {
                window.localStorage.removeItem("withingsNonce");
                window.netlifyIdentity.currentUser().update({});
                this.setState({
                  withingsIsConnected: true
                });
              } else {
                return Promise.reject(result);
              }
            })
            .then(() => {
              //console.log("toggleing loading and triggering success banner");
              this.toggleLoading();
              this.successTimeout("Successfully connected!");
              if (this.props.source === "") {
                this.props.updateSource("withings", true);
              }
            })
            .catch(error => {
              this.toggleLoading();
              window.localStorage.removeItem("withingsNonce");
              console.error("Error:", error);
              this.setState({
                fetchError: true,
                fetchErrorDetails:
                  error.message || error.error || error.status || error
              });
            });
        });
      } else {
        navigate("/account/", { replace: true });
      }
    }
  }

  loadingAnimation() {
    if (this.state.loadingText === "Loading...") {
      this.setState({
        loadingText: "Loading"
      });
    } else {
      this.setState(prevState => {
        return {
          loadingText: prevState.loadingText + "."
        };
      });
    }
  }

  toggleLoading(callback = null) {
    if (this.state.loadingInterval != null) {
      window.clearInterval(this.state.loadingInterval);
      this.setState(
        {
          isLoading: false,
          loadingInterval: null
        },
        callback
      );
    } else {
      let loadingInterval = window.setInterval(this.loadingAnimation, 500);
      this.setState(
        {
          isLoading: true,
          loadingInterval: loadingInterval
        },
        callback
      );
    }
  }

  handleWithingsClick(e) {
    e.preventDefault();

    const scope = `user.metrics,user.info`;
    const redirect_uri = process.env.GATSBY_REDIRECT_URI;

    const state = uuid() + "-withings";
    window.localStorage.setItem("withingsNonce", JSON.stringify(state));
    const AuthCodeUrl = `https://account.withings.com/oauth2_user/authorize2?response_type=code&client_id=${process.env.GATSBY_WITHINGS_CLIENT_ID}&state=${state}&scope=${scope}&redirect_uri=${redirect_uri}`;
    window.location.assign(AuthCodeUrl);
  }

  handleWithingsDisconnect(e) {
    e.preventDefault();
    if (
      !window.confirm(
        "Clicking 'OK' will disconnect your Withings account from SmartScaleSync."
      )
    )
      return;
    this.toggleLoading(() => {
      generateHeaders({ "Content-Type": "application/json" })
        .then(headers => {
          return fetch(
            `${process.env.GATSBY_LAMBDA_ENDPOINT_PROD}withingsCancel_lambda`,
            {
              method: "POST",
              headers,
              body: JSON.stringify({
                stripe_id: window.netlifyIdentity.currentUser().app_metadata
                  .stripe_id,
                source: this.props.source
              })
            }
          );
        })
        .then(result => result.json())
        .then(result => {
          if (result.status === "succeeded") {
            window.netlifyIdentity.currentUser().update({});
            this.setState({
              withingsIsConnected: false
            });
          } else {
            return Promise.reject(result);
          }
        })
        .then(() => {
          this.toggleLoading();
          this.successTimeout("Successfully disconnected!");
          if (this.props.source === "withings") {
            this.props.updateSource("", true);
          }
        })
        .catch(err => {
          console.error("Error:", err);
          this.toggleLoading();
          this.setState({
            fetchError: true,
            fetchErrorDetails: err.message || err.error || err.status || err
          });
        });
    });
  }

  render() {
    return (
      <div>
        <Logo src={withingsLogo} />
        {this.state.fetchError && (
          <ErrorBanner error={this.state.fetchErrorDetails} />
        )}
        {!this.state.withingsIsConnected ? (
          <div>
            <div>
              <StatusP>Status:</StatusP>
              <StatusP className="disconnected">Not Connected</StatusP>
              {this.state.successMessage && (
                <SuccessBanner message={this.state.successMessage} />
              )}
            </div>
            <ButtonParagraph
              className={this.state.isLoading && "loading"}
              onClick={!this.state.isLoading ? this.handleWithingsClick : null}
            >
              {this.state.isLoading
                ? this.state.loadingText
                : "Connect to Withings"}
            </ButtonParagraph>
          </div>
        ) : (
          <div>
            <div>
              <StatusP>
                {`Status: Connected ${
                  this.props.source === "withings" ? "as source" : ""
                }`}
              </StatusP>
              {this.state.successMessage && (
                <SuccessBanner message={this.state.successMessage} />
              )}
            </div>
            {this.props.source !== "withings" &&
              this.props.source !== "garmin" && (
                <ButtonParagraph
                  className={this.state.isLoading && "loading"}
                  onClick={() => {
                    if (
                      !window.confirm(
                        `Clicking 'OK' will set Withings as your weight source${
                          this.props.source === "fitbit"
                            ? " (and Fitbit will no longer be your weight source)"
                            : this.props.source === "garmin"
                            ? " (and Garmin will no longer be your weight source)"
                            : ""
                        }.`
                      )
                    ) {
                      return;
                    }
                    this.toggleLoading(() => {
                      this.props.updateSource("withings", false, () => {
                        this.toggleLoading(() => {
                          this.successTimeout(
                            "Successfully connected as source!"
                          );
                        });
                      });
                    });
                  }}
                >
                  Set as source
                </ButtonParagraph>
              )}
            <ButtonParagraph
              className={this.state.isLoading && "loading"}
              onClick={
                !this.state.isLoading ? this.handleWithingsDisconnect : null
              }
            >
              {this.state.isLoading ? this.state.loadingText : "Disconnect"}
            </ButtonParagraph>
          </div>
        )}
      </div>
    );
  }
}
