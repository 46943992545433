import styled from "@emotion/styled";

export const StatusP = styled.p`
  margin: 0;
  margin-right: 5px;
  padding: 0;
  display: inline;

  &.disconnected {
    color: rgb(153, 153, 153);
    font-weight: bold;
  }
`;

export const ButtonParagraph = styled.p`
  text-decoration: none;
  color: #007fb6;
  cursor: pointer;
  margin-right: 7px;
  display: inline;

  &:hover {
    text-decoration: underline;
    color: rgb(0, 158, 255);
  }

  &.loading {
    color: rgb(153, 153, 153);
    text-decoration: none;
  }
`;
