import React from "react";
import fitbitLogo from "../../images/fitbit-logo.png";
import styled from "@emotion/styled";
import generateHeaders from "./generateHeaders";
import uuid from "uuid/v4";
import queryString from "query-string";
import { navigate } from "gatsby";
import ErrorBanner from "../../components/errorBanner";
import SuccessBanner, { successTimeout } from "../../components/successBanner";
import { ButtonParagraph, StatusP } from "./platformStyledComponents";

const Logo = styled.img`
  width: 100px;
  display: block;
  margin-top: 16px;
  margin-bottom: 5px;
`;

export default class Fitbit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      loadingInterval: null,
      loadingText: "Loading",
      fetchError: false,
      fetchErrorDetails: null,
      successMessage: null,
      fitbitIsConnected: false,
      fitbitAccount: null
    };

    this.handleFitbitClick = this.handleFitbitClick.bind(this);
    this.successTimeout = successTimeout.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.loadingAnimation = this.loadingAnimation.bind(this);
    this.handleFitbitDisconnect = this.handleFitbitDisconnect.bind(this);
  }

  componentWillUnmount() {
    if (this.state.isLoading) {
      this.toggleLoading();
    }
    this.mounted = false;
  }

  componentDidMount() {
    //console.log("fitbit mounted");
    this.mounted = true;
    if (
      window.netlifyIdentity.currentUser().app_metadata.fitbitIntegration !=
      null
    ) {
      this.setState({
        fitbitIsConnected: true,
        fitbitAccount: window.netlifyIdentity.currentUser().app_metadata
          .fitbit_obfuscated
      });
    }

    let fitbitNonce =
      typeof window !== "undefined" &&
      window.localStorage.getItem("fitbitNonce")
        ? JSON.parse(window.localStorage.getItem("fitbitNonce"))
        : null;

    if (this.props.query != null && this.props.query !== "") {
      const values = queryString.parse(this.props.query);
      if (fitbitNonce === values.state) {
        //console.log(this.props.source);
        this.toggleLoading(() => {
          generateHeaders({ "Content-Type": "application/json" })
            .then(headers => {
              if (values.error != null) {
                return Promise.reject(values.error);
              }
              return fetch(
                `${process.env.GATSBY_LAMBDA_ENDPOINT_PROD}getFitbitToken_lambda`,
                {
                  method: "POST",
                  headers,
                  body: JSON.stringify({
                    code: values.code,
                    stripe_id: window.netlifyIdentity.currentUser().app_metadata
                      .stripe_id,
                    source: this.props.source
                  })
                }
              );
            })
            .then(result => result.json())
            .then(result => {
              if (result.status === "succeeded") {
                window.localStorage.removeItem("fitbitNonce");
                return window.netlifyIdentity.currentUser().update({});
              } else {
                return Promise.reject(result);
              }
            })
            .then(() => {
              this.setState(
                {
                  fitbitIsConnected: true,
                  fitbitAccount: window.netlifyIdentity.currentUser()
                    .app_metadata.fitbit_obfuscated
                },
                () => {
                  this.toggleLoading();
                  this.successTimeout("Successfully connected!");
                  if (this.props.source === "") {
                    this.props.updateSource("fitbit", true);
                  }
                }
              );
            })
            .catch(error => {
              this.toggleLoading();
              window.localStorage.removeItem("fitbitNonce");
              console.error("Error:", error);
              this.setState({
                fetchError: true,
                fetchErrorDetails:
                  error.message || error.error || error.status || error
              });
            });
        });
      } else {
        navigate("/account/", { replace: true });
      }
    }
  }

  loadingAnimation() {
    if (this.state.loadingText === "Loading...") {
      this.setState({
        loadingText: "Loading"
      });
    } else {
      this.setState(prevState => {
        return {
          loadingText: prevState.loadingText + "."
        };
      });
    }
  }

  toggleLoading(callback = null) {
    if (this.state.loadingInterval != null) {
      window.clearInterval(this.state.loadingInterval);
      this.setState(
        {
          isLoading: false,
          loadingInterval: null
        },
        callback
      );
    } else {
      let loadingInterval = window.setInterval(this.loadingAnimation, 500);
      this.setState(
        {
          isLoading: true,
          loadingInterval: loadingInterval
        },
        callback
      );
    }
  }

  handleFitbitClick(e) {
    e.preventDefault();
    //console.log("current source:", this.props.source);
    const scope = `weight profile`;
    const redirect_uri = process.env.GATSBY_REDIRECT_URI;
    const state = uuid() + "-fitbit";
    window.localStorage.setItem("fitbitNonce", JSON.stringify(state));
    const AuthCodeUrl = `https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=${process.env.GATSBY_FITBIT_CLIENT_ID}&state=${state}&scope=${scope}&redirect_uri=${redirect_uri}`;
    window.location.assign(AuthCodeUrl);
  }

  handleFitbitDisconnect(e) {
    e.preventDefault();
    if (
      !window.confirm(
        "Clicking 'OK' will disconnect your Fitbit account from SmartScaleSync."
      )
    )
      return;
    this.toggleLoading(() => {
      generateHeaders({ "Content-Type": "application/json" })
        .then(headers => {
          return fetch(
            `${process.env.GATSBY_LAMBDA_ENDPOINT_PROD}fitbitCancel_lambda`,
            {
              method: "POST",
              headers,
              body: JSON.stringify({
                stripe_id: window.netlifyIdentity.currentUser().app_metadata
                  .stripe_id,
                source: this.props.source
              })
            }
          );
        })
        .then(result => result.json())
        .then(result => {
          if (result.status === "succeeded") {
            window.netlifyIdentity.currentUser().update({});
            this.setState({
              fitbitIsConnected: false,
              fitbitAccount: null,
              source: false
            });
          } else {
            return Promise.reject(result);
          }
        })
        .then(() => {
          this.toggleLoading();
          this.successTimeout("Successfully disconnected!");
          if (this.props.source === "fitbit") {
            this.props.updateSource("", true);
          }
        })
        .catch(err => {
          console.error("Error:", err);
          this.toggleLoading();
          this.setState({
            fetchError: true,
            fetchErrorDetails: err.message || err.error || err.status || err
          });
        });
    });
  }

  render() {
    return (
      <div>
        <Logo src={fitbitLogo} />
        {this.state.fetchError && (
          <ErrorBanner error={this.state.fetchErrorDetails} />
        )}
        {!this.state.fitbitIsConnected ? (
          <div>
            <div>
              <StatusP>Status:</StatusP>
              <StatusP className="disconnected">Not Connected</StatusP>
              {this.state.successMessage && (
                <SuccessBanner message={this.state.successMessage} />
              )}
            </div>
            <ButtonParagraph
              className={this.state.isLoading && "loading"}
              onClick={!this.state.isLoading ? this.handleFitbitClick : null}
            >
              {this.state.isLoading
                ? this.state.loadingText
                : "Connect to Fitbit"}
            </ButtonParagraph>
          </div>
        ) : (
          <div>
            <div>
              <StatusP>
                {`Status: Connected ${
                  this.props.source === "fitbit" ? "as source " : ""
                }(${this.state.fitbitAccount ||
                  window.netlifyIdentity.currentUser().app_metadata
                    .fitbit_obfuscated})`}
              </StatusP>
              {this.state.successMessage && (
                <SuccessBanner message={this.state.successMessage} />
              )}
            </div>
            {/* {this.props.source !== "fitbit" && this.props.source !== "garmin" && (
              <ButtonParagraph
                className={this.state.isLoading && "loading"}
                onClick={() => {
                  if (
                    !window.confirm(
                      `Clicking 'OK' will set Fitbit as your weight source${
                        this.props.source === "withings"
                          ? " (and Withings will no longer be your weight source)"
                          : this.props.source === "garmin"
                          ? " (and Garmin will no longer be your weight source)"
                          : ""
                      }.`
                    )
                  ) {
                    return;
                  }
                  this.toggleLoading(() => {
                    this.props.updateSource("fitbit", false, () => {
                      this.toggleLoading(() => {
                        this.successTimeout(
                          "Successfully connected as source!"
                        );
                      });
                    });
                  });
                }}
              >
                Set as source
              </ButtonParagraph>
            )} */}
            <ButtonParagraph
              className={this.state.isLoading && "loading"}
              onClick={
                !this.state.isLoading ? this.handleFitbitDisconnect : null
              }
            >
              {this.state.isLoading ? this.state.loadingText : "Disconnect"}
            </ButtonParagraph>
          </div>
        )}
      </div>
    );
  }
}
